const domains = {
    PUBLIC_URL: process.env.PUBLIC_URL || '/',
    HOST_DOMAIN: process.env.REACT_APP_API_DOMAIN || 'http://localhost:5000',
    URL_FILE_USER: process.env.REACT_APP_URL_FILE_USER || '/asset/file.json',
};
const bg_color = {
    color1 : process.env.REACT_APP_COLOR1 ? `#${process.env.REACT_APP_COLOR1}` : "#f0f5d7",
    color2 : process.env.REACT_APP_COLOR2 ? `#${process.env.REACT_APP_COLOR2}` : "#e0f3d2"
}
const LIMIT_MSG = {
    MSG: process.env.REACT_APP_LIMIT_MSG || 100,
    NOTI: process.env.REACT_APP_LIMIT_NOTI || 50
}
export {
    domains,
    LIMIT_MSG,
    bg_color
}
