import React, { useContext, useEffect, useState, useRef } from 'react'
import { MainContext } from 'Context/mainContext'
import { SocketContext } from 'Context/socketContext'
import { Flex} from "@chakra-ui/react"
import { IoMdClose } from "react-icons/io";
import ScrollToBottom from 'react-scroll-to-bottom';
// import { UsersContext } from 'Context/usersContext'
import { VietIME } from 'text_vni/vime'
import ContentMessage from '../Content/ContentMessage'
const useFocus = () => {
    const htmlElRef = useRef(null)
    const setFocus = () => {htmlElRef.current &&  htmlElRef.current.focus()}

    return [ htmlElRef, setFocus ] 
}
const TabMessage = (props) => {
    // const checkApp = window.localStorage.getItem('app') === "true" ? true : false;
    const { name, room } = useContext(MainContext)
    const g_vietTyper = new VietIME();
    const socket = useContext(SocketContext)
    const { messages, isConnected } = props
    const [inputRef, setInputFocus] = useFocus()

    const [message, setMessage] = useState('')
    const [msgRep, setMsgRep] = useState(null)
    const [goToBottom, setGoToBottom] = useState(false)

    const addReply = (msg) => {
        setMsgRep(msg)
    }

    useEffect(() => {
        setInputFocus()
        // const handleResize = () => {
        //     alert('window.innerHeight2 ' + window.innerWidth, window.innerWidth)
        //     // setTimeout(() => {
        //     //     alert('window.innerHeight3', window.innerWidth)
        //     //     console.log('window.innerHeight3', window.innerWidth)
        //     // }, 100);
            
        // };

        // window.addEventListener("resize", handleResize);
        // return () => {
        //     window.removeEventListener("resize", handleResize);
        // };
        
    }, []);

    const _handleKeyPress = (e, id) => {
        let $this = document.getElementById(id);
        g_vietTyper.targetOnKeyPress( e, $this );
        if(message === '') return
        setMessage(e.target.value)
    }
    const _handleKeyDown = (e, id) => {
        if (e.key === 'Enter') {
            handleSendMessage()
        }
    }

    const handleSendMessage = () => {
        let mes = message.trim()
        if(mes === '') return
        socket.emit('sendMessage', {user: name, content: mes, rep: msgRep}, () => {})
        setMessage('');
        setMsgRep(null)
        setGoToBottom(true)
    }

    const convertNameRoom = (name) => {
        let maxLength = 30
        if (name.length > maxLength) {
        return name.slice(0, maxLength) + "...";
        }
        return name;
    }
    return (
        <Flex className='room' flexDirection='column' width={{ base: "100%" }} height={{ base: "100%" }}>
            <ScrollToBottom className={`messages ContainerElementID ${msgRep ? 'rep_active': ''}`} followButtonClassName="button_go_bottom" debug={false} mode="bottom">
                <ContentMessage room={room} goToBottom={goToBottom} setGoToBottom={setGoToBottom} messages={messages} addReply={addReply} name={name} setInputFocus={setInputFocus}/>
            </ScrollToBottom>
            <div className='form'>
                {msgRep && (
                    <div className="reply">
                        <div className="reply_label">
                            <div>Đang trả lời </div>
                            <div className="max_string">{msgRep.user}</div>
                            
                        </div>
                        <div className="reply_content max_string">
                            {msgRep.content}
                        </div>
                        <div className="reply_close" onClick={() => {
                                setMsgRep(null)
                        }}><IoMdClose /></div>
                    </div>
                    
                    
                )}
                <Flex >
                    
                    <div className="input_message">
                        <input type="text" id='inboxMessage' autoComplete="off" ref={inputRef} disabled={!isConnected.connect ? true : false} placeholder={`Gửi đến ${convertNameRoom(room)}`} value={message}
                            onChange={e => {
                                setMessage(e.target.value)
                            }}
                            onKeyPress={(e)=> {
                                _handleKeyPress(e, 'inboxMessage')
                            }}
                            onKeyDown={(e)=> {
                                _handleKeyDown(e)
                        }}
                        />
                    </div>
                    
                    <Flex className={`${message === '' ? '' : 'active_send'} button_send_message`} onClick={handleSendMessage} alignItems={'center'} justifyContent={"space-between"}>
                        <div className="text-input">Gửi</div>
                        <div>
                            <img alt="" src="/primary-shape.svg"/>
                        </div>
                        
                    </Flex>
                    {/* <IconButton colorScheme='blue' minW='30px' h='30px' isRound='true' icon={<RiSendPlaneFill />} onClick={handleSendMessage} disabled={message === '' ? true : false}>Send</IconButton> */}
                </Flex>
            </div>
            
        </Flex>
    )
}
export default TabMessage;