import React, { useEffect } from 'react'
import { useHistory } from 'react-router-dom'

const Logout = () => {
    
    const history = useHistory()
    
    useEffect(()=> {
      const logout = () => {
        window.localStorage.clear()
        history.push('/')
        history.go(0)
      }
      logout()
    }, [])
    
    
    return (
        <></>
    )
}

export default Logout
